@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "Proxima Nova";
  src: url("/fonts/ProximaNova-Bold.woff2") format("woff2");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "Proxima Nova";
  src: url("/fonts/ProximaNova-Regular.woff2") format("woff2");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Nexa";
  src: url("/fonts/NexaBold.otf") format("opentype");
  font-weight: 700;
  font-style: normal;
}

* {
  font-family: "Proxima Nova";

  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

a {
  color: inherit;
  text-decoration: none;
}

.container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.slider {
  position: relative;
  width: calc(100% - 60px);
}

.slider__track,
.slider__range,
.slider__left-value,
.slider__right-value {
  position: absolute;
}

.slider__track,
.slider__range {
  border-radius: 3px;
  height: 3px;
}

.slider__track {
  background-color: #ced4da;
  width: 100%;
  z-index: 1;
}

.slider__range {
  background-color: grey;
  z-index: 2;
}

.slider__left-value,
.slider__right-value {
  color: #dee2e6;
  font-size: 12px;
  margin-top: 20px;
}

.slider__left-value {
  left: 6px;
}

.slider__right-value {
  right: -4px;
}

/* Removing the default appearance */
.thumb,
.thumb::-webkit-slider-thumb {
  -webkit-appearance: none;
  -webkit-tap-highlight-color: transparent;
}

.thumb {
  pointer-events: none;
  position: absolute;
  height: 0;
  width: calc(100% - 2px);
  outline: none;
}

.thumb--zindex-3 {
  z-index: 3;
}

.thumb--zindex-4 {
  z-index: 4;
}

.thumb--zindex-5 {
  z-index: 5;
}

/* For Chrome browsers */
.thumb::-webkit-slider-thumb {
  background-color: #f1f5f7;
  border: none;
  border-radius: 50%;
  box-shadow: 0 0 1px 1px #ced4da;
  cursor: pointer;
  height: 18px;
  width: 18px;
  margin-top: 4px;
  pointer-events: all;
  position: relative;
}

/* For Firefox browsers */
.thumb::-moz-range-thumb {
  background-color: #f1f5f7;
  border: none;
  border-radius: 50%;
  box-shadow: 0 0 1px 1px #ced4da;
  cursor: pointer;
  height: 18px;
  width: 18px;
  margin-top: 4px;
  pointer-events: all;
  position: relative;
}

input[type="range"]::-webkit-slider-thumb {
  width: 30px;
  height: 30px;
  cursor: pointer;
  background-image: url("/assets/slider-pointer.svg");
  background-repeat: no-repeat;
  background-position-x: 10px;
  background-position-y: 8px;
}

table,
th {
  border: none;
}

.apexcharts-canvas {
  border: none;
}

.custom-select {
  appearance: none;
  background-image: url("/assets/expand_more.svg");
  background-repeat: no-repeat;
  background-position: calc(100% - 10px) center;
}
.bulletedIcon li::marker {
  content: "• ";
}
ol.list-decimal {
  list-style-type: decimal;
}
ul.list-disc {
  list-style-type: disc;
}
ol.list-inside,
ul.list-inside {
  list-style-position: inside;
}
ol {
  list-style-type: decimal;
}

ul {
  list-style-type: initial;
}

:focus-visible {
  outline: none;
}

.es-badge-container {
  z-index: 50 !important;
}
